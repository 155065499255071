function shuffle(array, indexes) {
  /**
   * https://stackoverflow.com/a/2450976
   **/
  var currentIndex = array.length, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    swap(array, currentIndex, randomIndex);

    if(indexes) {
      // Swap indexes to match
      swap(indexes, currentIndex, randomIndex);
    }
  }
}

function swap(array, a, b) {
  var tempValue = array[a];
  array[a] = array[b];
  array[b] = tempValue;
}

function range(start, end) {
  if(end === undefined) {
    end = start;
    start = 0;
  }

  var length = end - start;
  return Array.from({length}, (_, i) => start + i);
}

function durationToString(duration) {
  var newDuration = duration.shiftTo('hours', 'minutes', 'seconds');
  var format;
  if(newDuration.hours >= 1) {
    format = "h:mm:ss";
  }
  else if(newDuration.minutes >= 10) {
    format = "mm:ss";
  }
  else if(newDuration.minutes >= 1) {
    format = "m:ss";
  }
  else {
    format = "ss";
  }

  return newDuration.toFormat(format);
}

function flatten(arrayOfArrays) {
  return arrayOfArrays.reduce((a, b) => a.concat(b));
}

function randInt(max) {
  if(max === undefined) {
    max = 1;
  }

  return Math.floor(max * Math.random());
}

function storageAvailable(type) {
  /* From https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API */
  var storage;
  try {
    storage = window[type];
    var x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  }
  catch(e) {
    return e instanceof DOMException && (
      // everything except Firefox
      e.code === 22 ||
      // Firefox
      e.code === 1014 ||
      // test name field too, because code might not be present
      // everything except Firefox
      e.name === 'QuotaExceededError' ||
      // Firefox
      e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      (storage && storage.length !== 0);
  }
}

export {
  durationToString,
  flatten,
  randInt,
  range,
  shuffle,
  storageAvailable,
  swap
};