import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";

import * as MC from './my_components';
import {config} from './subsonic_config';

var navPanelRouteInfos = [
  {
    path: "/",
    exact: true,
    component: MC.HomeNavPanel
  },
  {
    path: "/playlists",
    exact: true,
    component: MC.PlaylistsNavPanel
  },
  {
    path: "/playlists/:id",
    exact: true,
    component: MC.PlaylistNavPanel
  },
  {
    path: "/artists",
    exact: true,
    component: MC.AllArtistsNavPanel
  },
  {
    path: "/artists/:id",
    exact: true,
    component: MC.ArtistNavPanel
  },
  {
    path: "/albums",
    exact: true,
    component: MC.AllAlbumsNavPanel
  },
  {
    path: "/albums/:id",
    exact: true,
    component: MC.AlbumNavPanel
  }
];

function PrivateRoute(routeInfo) {
  // Store input.component in var Component, and other attributes in a new object called rest
  var { component: Component, ...rest } = routeInfo;

  // Make the route render the specified component (and pass props to it) iff the user logged in
  var render = (props) => {
    if(config.getIsAuthenticated()) {
      return (<Component {...props} />);
    }
    else {
      return (
        <Redirect to={{pathname: "/login", state: {from: props.location}}} />
      );
    }
  };

  // Return the route
  return (<Route {...rest} render={render} />);
}

function MyAppRouter() {
  return (
    <Router>
      <Switch>
        <Route path="/login" exact={true} component={MC.LoginPage} />
        {navPanelRouteInfos.map((routeObj, index) => (
          <PrivateRoute key={index} path={routeObj.path} exact={routeObj.exact} component={routeObj.component} />
        ))}
        <PrivateRoute component={() => (<Redirect to="/" />)} />
      </Switch>
      <MC.PlayerPanel />
    </Router>
  );
}

ReactDOM.render(<MyAppRouter />, document.getElementById('root'));