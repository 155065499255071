import Amplitude from 'amplitudejs';
import React from 'react';
import $ from 'jquery';
import {Link, Redirect} from "react-router-dom";
import {Duration} from "luxon";
import md5 from "md5";

import 'normalize.css';

import {durationToString, flatten, randInt, range, shuffle, swap} from "./util";
import {restURL} from "./subsonic";
import {config} from "./subsonic_config";

import './css/now-playing.css';

var queueNewSongEventSubscribers = [];

function publishQueueNewSongsEvent(songs, songIndex) {
  queueNewSongEventSubscribers.forEach((subscriber) => {
    subscriber.queueNewSongs(songs, songIndex);
  });
}

function addQueueNewSongEventSubscriber(subscriber) {
  queueNewSongEventSubscribers.push(subscriber);
}

class NavPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToLogin: false
    };
  }

  componentDidMount() {
    config.addAuthChangedEventSubscriber(this);
  }

  componentWillUnmount() {
    config.removeAuthChangedEventSubscriber(this);
  }

  onConfigAuthenticationChanged(newIsAuthenticated) {
    this.setState({redirectToLogin: !newIsAuthenticated});
  }

  getNavigation() {
    return this.props.nav;
  }

  getHeader() {
    return this.props.header;
  }

  getComponent() {
    return this.props.component;
  }

  logout = () => {
    config.setIsAuthenticated(false);
    config.resetConfig();
  };

  render() {
    if(this.state.redirectToLogin) {
      return (<Redirect to="/login" />);
    }
    else {
      return (
        <div className="navigation-panel">
          <div className="navigation-panel-banner">
            <div className="navigation-panel-path-display">
              {this.getNavigation()}
            </div>
            <div className="navigation-panel-user-display">
              <span className="navigation-panel-user-info">
                {"Logged in as " + config.getUser()}
              </span>
              <button className="navigation-panel-logout" onClick={this.logout}>Logout</button>
            </div>
          </div>
          <div className="navigation-panel-scroll-container">
            <div className="navigation-panel-scroll-buffer"></div>
            <div className="navigation-panel-header">
              <h1>{this.getHeader()}</h1>
            </div>
            <div className="navigation-panel-content">
              {this.getComponent()}
            </div>
          </div>
        </div>
      );
    }
  }
}

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      server: "",
      username: "",
      password: "",
      redirectToReferrer: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    config.addAuthChangedEventSubscriber(this);
  }

  componentWillUnmount() {
    config.removeAuthChangedEventSubscriber(this);
  }

  onConfigAuthenticationChanged(newIsAuthenticated) {
    this.setState({redirectToReferrer: newIsAuthenticated});
  }

  handleChange(event) {
    var target = event.target;
    this.setState({
      [target.name]: target.value
    });
  };

  handleSubmit(event) {
    event.preventDefault();

    var seed = Math.random().toString(36).slice(2);
    var token = md5(this.state.password + seed);
    config.setServer(this.state.server);
    config.setUser(this.state.username);
    config.setSeed(seed);
    config.setToken(token);

    $.ajax({
      url: restURL("ping", ""),
      success: (data) => {
        var responseNode = data.firstElementChild;
        var status = responseNode.getAttribute("status");
        if(status === "ok") {
          config.setIsAuthenticated(true);
        }
        else {
          alert("Login failed");
        }
      }
    });
  }

  render() {
    var from = this.props.location.state || {pathname: "/"};
    var redirectToReferrer = this.state.redirectToReferrer;

    if(redirectToReferrer) {
      return (<Redirect to={from} />);
    }

    var submitButton;
    if(this.state.server.length > 0 && this.state.username.length > 0 && this.state.password.length > 0)
      submitButton = (<input type="submit" value="Submit" />);
    else
      submitButton = (<input type="submit" value="Submit" disabled />);

    return (
      <div className="login-page">
        <h1>Login</h1>
        <form className="login-page-form" onSubmit={this.handleSubmit}>
          <div className="login-page-form-server">
            <label>Server (e.g. "http://example.com:4040")</label>
            <input type="text" name="server" onChange={this.handleChange} />
          </div>
          <div className="login-page-form-username">
            <label>Username:</label>
            <input type="text" name="username" onChange={this.handleChange} />
          </div>
          <div className="login-page-form-password">
            <label>Password:</label>
            <input type="password" name="password" onChange={this.handleChange} />
          </div>
          <div className="login-page-form-submit">
            {submitButton}
          </div>
        </form>
      </div>
    )
  }
}

class HomeNavPanel extends NavPanel {
  getNavigation() {
    return (
      <div><span>Home</span></div>
    );
  }

  getHeader() {
    return "Home";
  }

  getComponent() {
    return (
      <div className="home-view">
        <Link to="/artists">
          <div>Artists</div>
        </Link>
        <Link to="/albums">
          <div>Albums</div>
        </Link>
        <Link to="/playlists">
          <div>Playlists</div>
        </Link>
      </div>
    );
  }
}

class PlaylistsNavPanel extends NavPanel {
  getNavigation() {
    return (
      <div>
        <Link to="/">Home</Link>
        <span> &gt; </span>
        <span>Playlists</span>
      </div>
    );
  }

  getHeader() {
    return "Playlists";
  }

  getComponent() {
    return (<AllPlaylistsView />);
  }
}

class PlaylistNavPanel extends NavPanel {
  constructor(props) {
    super(props);
    this.id = props.match.params.id;

    this.state = {
      name: null,
      songs: []
    };

    $.ajax({
      url: restURL("getPlaylist", "id="+this.id),
      success: (data) => {
        // Get song XML nodes corresponding to the given playlist
        var playlistNode = data.firstElementChild.firstElementChild;
        var songNodes = playlistNode.children;
        var songs = songNodesToObjects(songNodes);

        this.setState({
          name: playlistNode.getAttribute("name"),
          songs: songs
        });
      }
    });
  }

  getNavigation() {
    return (
      <div>
        <Link to="/">Home</Link>
        <span> &gt; </span>
        <Link to="/playlists">Playlists</Link>
        <span> &gt; </span>
        <span>{this.state.name}</span>
      </div>
    );
  }

  getHeader() {
    return this.state.name;
  }

  getComponent() {
    return (
      <PlaylistView songs={this.state.songs} />
    )
  }
}

class AllArtistsViewItem extends React.Component {
  constructor(props) {
    super(props);
    this.artistId = this.props.artist.id;
    this.getArtistInfoFn = generateGetArtistInfoFn(this.artistId);
  }

  queueMySongs = () => {
    this.getArtistInfoFn().then((artistInfo) => {
      publishQueueNewSongsEvent(artistInfo.songs);
    });
  };

  render() {
    return (
      <div className="all-artists-view-item">
        <button className="all-artists-view-item-play" onClick={this.queueMySongs}>pl</button>
        <span>
          <Link to={"/artists/"+this.props.artist.id}>{this.props.artist.name}</Link>
        </span>
      </div>
    )
  }
}

class AllArtistsNavPanel extends NavPanel {
  constructor(props) {
    super(props);

    this.state = {
      artists: []
    };

    $.ajax({
      url: restURL("getIndexes", ""),
      success: (data) => {
        var letterIndexNodes = data.firstElementChild.firstElementChild.children;
        var artists = [];
        [].forEach.call(letterIndexNodes, (letterIndexNode) => {
          var artistNodes = letterIndexNode.children;
          [].forEach.call(artistNodes, (artistNode) => {
            var id = artistNode.getAttribute("id");
            var name = artistNode.getAttribute("name");
            artists.push({
              "id": id,
              "name": name
            });
          });
        });

        this.setArtists(artists);
      }
    });
  }

  setArtists(artists) {
    this.setState((prevState, props) => ({
      artists: artists
    }));
  }

  getNavigation() {
    return (
      <div>
        <Link to="/">Home</Link>
        <span> &gt; </span>
        <span>Artists</span>
      </div>
    );
  }

  getHeader() {
    return "Artists";
  }

  getComponent() {
    var allArtistsViewItems = this.state.artists.map((artist, index) => (
      <AllArtistsViewItem key={index} artist={artist} />
    ));

    return (
      <div className="all-artists-view">
        {allArtistsViewItems}
      </div>
    );
  }
}

class AllAlbumsNavPanel extends NavPanel {
  constructor(props) {
    super(props);

    this.state = {
      albums: []
    };

    this.generateGetAllAlbumInfoFn().then((newState) => {
      this.setState(newState);
    });
  }

  async generateGetAllAlbumInfoFn() {
    var artistsData = await $.ajax({
      url: restURL("getArtists", "")
    });
    var artistsNode = artistsData.firstElementChild.firstElementChild;
    var artistIndexNodes = artistsNode.children;

    var artistNodeLists = Array.from(artistIndexNodes).map((artistIndexNode) => Array.from(artistIndexNode.children));
    var artistNodes = flatten(artistNodeLists);

    var promises = Array.from(artistNodes).map((artistNode) => $.ajax({
      url: restURL("getArtist", "id=" + artistNode.getAttribute("id"))
    }));

    var artistNodes2 = await Promise.all(promises);
    var albumNodesList = artistNodes2.map((artistNode2) => (
      Array.from(artistNode2.firstElementChild.firstElementChild.children)
    ));
    var albumNodes = flatten(albumNodesList);
    var albums = albumNodes.map((albumNode) => {
      var id = albumNode.getAttribute("id");
      var name = albumNode.getAttribute("name");
      var genre = albumNode.getAttribute("genre");
      var year = albumNode.getAttribute("year");
      return {
        id: id,
        name: name,
        genre: genre,
        year: year,
        cover_art_url: restURL("getCoverArt", "id=al-"+id)
      };
    });

    albums.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

    return {
      albums: albums
    };
  }

  getNavigation() {
    return (
      <div>
        <Link to="/">Home</Link>
        <span> &gt; </span>
        <span>Albums</span>
      </div>
    );
  }

  getHeader() {
    return "Albums";
  }

  getComponent() {
    return (
      <div className="all-albums-view">
        <AlbumsView albums={this.state.albums} size={300} />
      </div>
    );
  }
}

class AlbumsView extends React.Component {
  render() {
    var albumViewItems = this.props.albums.map((album, index) => (
      <AlbumsViewItem key={index} album={album} size={this.props.size} />
    ));

    return (
      <div className="albums-view">
        {albumViewItems}
      </div>
    )
  }
}

class AlbumsViewItem extends React.Component {
  constructor(props) {
    super(props);
    this.getAlbumInfoFn = generateGetAlbumInfoFn(this.props.album.id);
  }

  queueMySongs = () => {
    this.getAlbumInfoFn().then((albumInfo) => {
      publishQueueNewSongsEvent(albumInfo.songs);
    });
  };

  render() {
    var resizedImageUrl = this.props.album.cover_art_url + "&size=" + this.props.size;
    return (
      <div className="all-albums-view-item">
        <Link to={"/albums/"+this.props.album.id}>
          <img className="all-albums-view-item-art" src={resizedImageUrl} alt=""></img>
        </Link>
        <div className="all-albums-view-item-other">
          <button className="all-albums-view-item-play" onClick={this.queueMySongs}>pl</button>
          <span className="all-albums-view-item-name">{this.props.album.name}</span>
        </div>
      </div>
    )
  }
}

function songNodesToObjects(songNodes) {
  var songNodeArray = (Array.isArray(songNodes) ? songNodes : Array.from(songNodes));
  return songNodeArray.map((songNode) => {
    var songAttrs = (attrName) => (songNode.getAttribute(attrName));
    return {
      songId: songAttrs("id"),
      name: songAttrs("title"),
      artist: songAttrs("artist"),
      album: songAttrs("album"),
      url: restURL("stream", "id="+songAttrs("id")),
      cover_art_url: restURL("getCoverArt", "id="+songAttrs("coverArt")),
      duration: Duration.fromObject({seconds: songAttrs("duration")}),
      track: songAttrs("track")
    };
  });
}

function generateGetAlbumInfoFn(albumId) {
  return async () => {
    // Get song XML nodes corresponding to the given album
    var albumData = await $.ajax({
      url: restURL("getAlbum", "id="+albumId)
    });
    var albumNode = albumData.firstElementChild.firstElementChild;
    var songNodes = albumNode.children;

    // Create song objects sorted by track number
    var songs = songNodesToObjects(songNodes);
    songs.sort((a, b) => (a.track - b.track));

    return {
      name: albumNode.getAttribute("name"),
      songs: songs
    }
  }
}

class AlbumNavPanel extends NavPanel {
  constructor(props) {
    super(props);
    this.id = props.match.params.id;
    this.getAlbumInfoFn = generateGetAlbumInfoFn(this.id);

    this.state = {
      name: null,
      songs: []
    };

    this.getAlbumInfoFn().then((newState) => {
      this.setState(newState);
    });
  }

  getNavigation() {
    return (
      <div>
        <Link to="/">Home</Link>
        <span> &gt; </span>
        <Link to="/albums">Albums</Link>
        <span> &gt; </span>
        <span>{this.state.name}</span>
      </div>
    );
  }

  getHeader() {
    return this.state.name;
  }

  getComponent() {
    return (
      <PlaylistView songs={this.state.songs} />
    )
  }
}

function generateGetArtistInfoFn(artistId) {
  return async () => {
    var artistData = await $.ajax({
      url: restURL("getMusicDirectory", "id="+artistId)
    });
    var artistNode = artistData.firstElementChild.firstElementChild;
    var name = artistNode.getAttribute("name");
    // Node: This is the album node for this particular artist
    var albumNodes = artistNode.children;

    var promises = Array.from(albumNodes).map((albumNode) => $.ajax({
      url: restURL("getMusicDirectory", "id=" + albumNode.getAttribute("id"))
    }));

    var albumDatas = await Promise.all(promises);

    var albumNodes2 = albumDatas.map((albumData) => (albumData.firstElementChild.firstElementChild));
    var songNodes = albumNodes2.map((albumNode) => (
      Array.from(albumNode.children)
    ));
    var songNodes2 = flatten(songNodes);
    var songs = songNodesToObjects(songNodes2);

    songs.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

    return {
      name: name,
      songs: songs
    };
  };
}

class ArtistNavPanel extends NavPanel {
  constructor(props) {
    super(props);
    this.id = props.match.params.id;
    this.getArtistInfoFn = generateGetArtistInfoFn(this.id);

    this.state = {
      name: null,
      songs: []
    };

    this.getArtistInfoFn().then((newState) => {
      this.setState(newState);
    });
  }

  getNavigation() {
    return (
      <div>
        <Link to="/">Home</Link>
        <span> &gt; </span>
        <Link to="/artists">Artists</Link>
        <span> &gt; </span>
        <span>{this.state.name}</span>
      </div>
    );
  }

  getHeader() {
    return this.state.name;
  }

  getComponent() {
    return (
      <div className="artist-nav-panel-content">
        <PlaylistView songs={this.state.songs} />
      </div>
    )
  }
}

class AllPlaylistsViewItem extends React.Component {
  constructor(props) {
    super(props);
    this.playlist = this.props.playlist;
  }

  queueMyPlaylistIndex = () => {
    $.ajax({
      url: restURL("getPlaylist", "id="+this.playlist.id),
      success: (data) => {
        var playlistNode = data.firstElementChild.firstElementChild;
        var songNodes = playlistNode.children;
        var songs = songNodesToObjects(songNodes);

        publishQueueNewSongsEvent(songs);
      }
    });
  };

  render() {
    return (
      <div className="all-playlists-view-item">
        <button className="all-playlists-view-item-play" onClick={this.queueMyPlaylistIndex}>pl</button>
        <span className="all-playlists-view-item-title"><Link to={"/playlists/"+this.playlist.id}>{this.playlist.name}</Link></span>
        <span className="all-playlists-view-item-description">{this.playlist.description}</span>
        <span className="all-playlists-view-item-items">{this.playlist.numItems}</span>
        <span className="all-playlists-view-item-duration">{durationToString(this.playlist.duration)}</span>
      </div>
    )
  }
}

class AllPlaylistViewHeader extends React.Component {
  render() {
    return (
      <div className="all-playlists-view-header">
        <span className="all-playlists-view-header-play"></span>
        <span className="all-playlists-view-header-title">Title</span>
        <span className="all-playlists-view-header-description">Description</span>
        <span className="all-playlists-view-header-items">Items</span>
        <span className="all-playlists-view-header-duration">Duration</span>
      </div>
    )
  }
}

class AllPlaylistsView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      playlists: []
    };

    $.ajax({
      url: restURL("getPlaylists", ""),
      success: (data) => {
        var playlistNodes = data.firstElementChild.firstElementChild.children;
        var playlists = [];
        [].forEach.call(playlistNodes, (node) => {
          var id = node.getAttribute("id");
          var name = node.getAttribute("name");
          var comment = node.getAttribute("comment");
          var songCount = node.getAttribute("songCount");
          var duration = node.getAttribute("duration");
          playlists.push({
            "id": id,
            "name": name,
            "description": comment,
            "numItems": songCount,
            "duration": Duration.fromObject({seconds: duration})
          });
        });

        this.setPlaylists(playlists);
      }
    });
  }

  setPlaylists(playlists) {
    this.setState((prevState, props) => ({
      playlists: playlists
    }));
  }

  render() {
    var allPlaylistViewItems = this.state.playlists.map((playlist, index) => (
      <AllPlaylistsViewItem key={index} playlist={playlist} />
    ));

    return (
      <div className="all-playlists-view">
        <AllPlaylistViewHeader />
        {allPlaylistViewItems}
      </div>
    )
  }
}

class PlaylistViewItem extends React.Component {
  constructor(props) {
    super(props);
    this.parent = this.props.parent;
    this.song = this.props.song;
    this.songIndex = this.props.songIndex;
  }

  queueMySongIndex = () => {
    this.parent.queueSongIndex(this.songIndex);
  };

  render() {
    return (
      <div className="playlist-view-item">
        <button className="playlist-view-item-play" onClick={this.queueMySongIndex}>pl</button>
        <span className="playlist-view-item-title">{this.song.name}</span>
        <span className="playlist-view-item-artist">{this.song.artist}</span>
        <span className="playlist-view-item-album">{this.song.album}</span>
        <span className="playlist-view-item-duration">{durationToString(this.song.duration)}</span>
      </div>
    )
  }
}

class PlaylistViewHeader extends React.Component {
  render() {
    return (
      <div className="playlist-view-header">
        <span className="playlist-view-header-play"></span>
        <span className="playlist-view-header-title">Title</span>
        <span className="playlist-view-header-artist">Artist</span>
        <span className="playlist-view-header-album">Album</span>
        <span className="playlist-view-header-duration">Duration</span>
      </div>
    );
  }
}

class PlaylistView extends React.Component {
  queueSongIndex(songIndex) {
    publishQueueNewSongsEvent(this.props.songs, songIndex);
  }

  render() {
    var playlistViewItems = this.props.songs.map((song, index) =>
      <PlaylistViewItem key={index} song={song} songIndex={index} parent={this} />
    );

    return (
      <div className="playlist-view">
        <PlaylistViewHeader />
        {playlistViewItems}
      </div>
    );
  }
}


class QueueItem extends React.Component {
  render() {
    var song = this.props.song;
    return (
      <div className="queue-item amplitude-song-container" amplitude-song-index={this.props.songIndex}>
        <span className="queue-item-isactive"></span>
        <a className="queue-item-title amplitude-play" amplitude-song-index={this.props.songIndex} href="#">{song.name}</a>
        <span className="queue-item-artist">{song.artist}</span>
        <span className="queue-item-album">{song.album}</span>
        <span className="queue-item-duration">{durationToString(song.duration)}</span>
      </div>
    )
  }
}

class QueueHeader extends React.Component {
  render() {
    return (
      <div className="queue-header">
        <span className="queue-header-active"></span>
        <span className="queue-header-title">Title</span>
        <span className="queue-header-artist">Artist</span>
        <span className="queue-header-album">Album</span>
        <span className="queue-header-duration">Duration</span>
      </div>
    );
  }
}

class QueueView extends React.Component {
  render() {
    var queueItems = this.props.songs.map((song, index) =>
      <QueueItem key={index} song={song} songIndex={index} />
    );

    var classNames = "queue-view" + (this.props.display ? "" : " hidden");
    return (
      <div className={classNames}>
        <QueueHeader />
        {queueItems}
      </div>
    );
  }
}

class AlbumArtView extends React.Component {
  constructor(props) {
    super(props);

    this.parent = this.props.parent;

    this.state = {
      width: null,
      height: null
    };
  }

  render() {
    var classNames = "player-panel-album-art-container" + (this.props.display ? "" : " hidden");

    var style = {
      "height": this.parent.state.height - 165,
      "width": this.parent.state.width
    };

    return (
      <div className={classNames} style={style}>
        <img className="player-panel-album-art" amplitude-song-info="cover_art_url" alt="" />
      </div>
    )
  }
}


class VolumeControl extends React.Component {
  constructor(props) {
    super(props);
    this.parent = this.props.parent;
    this.state = {
      showVolumeSlider: false,
      volume: this.props.parent.state.volume
    };

    this.handleVolumeChange = this.handleVolumeChange.bind(this);
  }

  componentDidMount() {
    var volumeSliderNodes = document.getElementsByClassName("amplitude-volume-slider");
    Array.from(volumeSliderNodes).forEach((node) => {
      node.value = this.state.volume;
    });
  }

  onVolumeButtonPressed = () => {
    this.setState((prevState, props) => ({
      showVolumeSlider: !prevState.showVolumeSlider
    }));
  };

  handleVolumeChange(event) {
    var newVolume = parseInt(event.target.value);
    config.setVolume(newVolume);
    this.setState({
      volume: newVolume
    });
    this.parent.handleVolumeChange(event);
  };

  render() {
    return (
      <div className="volume-control">
        <button className="button" onClick={this.onVolumeButtonPressed}>vol</button>
        <input type="range" className="amplitude-volume-slider" style={{display: (this.state.showVolumeSlider ? "initial" : "none")}}
          onInput={this.handleVolumeChange} />
      </div>
    );
  }
}

class ProgressBar extends React.Component {
  render() {
    return (
      <div className="progress-bar">
        <input type="range" className="amplitude-song-slider" amplitude-main-song-slider="true"/>
        <br />
        <span className="cur-time">
          <span className="amplitude-current-minutes" amplitude-main-current-minutes="true"></span>:<span className="amplitude-current-seconds" amplitude-main-current-seconds="true"></span>
        </span>
        <progress className="amplitude-song-played-progress" amplitude-main-song-played-progress="true"></progress>
        <span className="max-time">
          <span className="amplitude-duration-minutes" amplitude-main-duration-minutes="true"></span>:<span className="amplitude-duration-seconds" amplitude-main-duration-seconds="true"></span>
        </span>
        <br />
        <progress className="amplitude-buffered-progress" value="0"></progress>
      </div>
    );
  }
}

class NowPlayingContainer extends React.Component {
  constructor(props) {
    super(props);
    this.parent = this.props.parent;
    this.state = {
      volume: this.props.parent.state.volume,
      isRepeating: this.props.parent.state.isRepeating
    };

    this.handleVolumeChange = this.handleVolumeChange.bind(this);
  }

  onRepeatButtonPressed = () => {
    var newIsRepeating = !this.state.isRepeating;
    this.setState({
      isRepeating: newIsRepeating
    });
    this.parent.toggleRepeat();
  };

  onShuffleButtonPressed = () => {
    this.parent.toggleShuffling();
  };

  onVisButtonPressed = () => {
    this.parent.switchVisualization();
  };

  handleVolumeChange(event) {
    this.setState({
      volume: parseInt(event.target.value)
    });
    this.parent.handleVolumeChange(event);
  }

  render() {
    var repeatButtonIsRepeatingClass = (this.state.isRepeating ? "amplitude-repeat-on" : "amplitude-repeat-off");

    return (
      <div className="now-playing-container">
        <ProgressBar />
        <div className="control-buttons">
          <button className="prev amplitude-prev">prev</button>
          <button className="play-pause amplitude-play-pause amplitude-paused" amplitude-main-play-pause="true"></button>
          <button className="next amplitude-next">next</button>
          <button className="stop amplitude-stop">stop</button>
        </div>
        <div className="song-info">
          <div className="title" amplitude-song-info="name" amplitude-main-song-info="true"></div>
          <div className="artist" amplitude-song-info="artist" amplitude-main-song-info="true"></div>
        </div>
        <div className="extra-buttons">
          <VolumeControl parent={this} />
          <button className="shuffle-button" data-is-shuffling={this.props.isShuffling} onClick={this.onShuffleButtonPressed}></button>
          <button className="visToggle" data-next-vis-type={this.props.nextVisType} onClick={this.onVisButtonPressed}></button>
          <button className={"repeat amplitude-repeat " + repeatButtonIsRepeatingClass} onClick={this.onRepeatButtonPressed}></button>
        </div>
      </div>
    );
  }
}

class PlayerPanel extends React.Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();

    this.visualizationTypes = ["playlist", "album-art"];

    this.wakeLock = null;

    this.state = {
      songs: [],
      reorderedSongs: [],
      reorderedIndexes: [],
      firstActiveSongIndex: 0,
      volume: config.getVolume(),
      isRepeating: config.getIsRepeating(),
      isShuffling: config.getIsShuffling(),
      shouldRestart: false,
      skipTo: 0,
      playOnRestart: false,
      visualization: this.visualizationTypes[config.getVisIndex()],
      visualizationIndex: config.getVisIndex(),
      display: config.getIsAuthenticated(),
      width: null,
      height: null
    };

    config.addAuthChangedEventSubscriber(this);
  }

  onConfigAuthenticationChanged(newIsAuthenticated) {
    Amplitude.pause();
    this.setState({
      display: newIsAuthenticated,
      shouldRestart: false
    });
  }

  shuffleSongs(songs, indexes, firstIndex) {
    shuffle(songs, indexes);

    // Find first active song and move it to front of reorderedSongs
    for(var i = 0; i <= indexes.length; i++) {
      if(indexes[i] === firstIndex) {
        swap(songs, 0, i);
        swap(indexes, 0, i);
        break;
      }
    }
  }

  updateSize = () => {
    var height = $(this.ref.current).height();
    var width = $(this.ref.current).width();

    this.setState({
      height: height,
      width: width,
      shouldRestart: false
    });
  };

  restartAmplitude() {
    // Amplitude.bindNewElements();  // Doesn't set active song in playlist view on load since setActiveContainer() is never called

    Amplitude.pause();

    Amplitude.init({
      "songs": this.state.reorderedSongs,
      "volume": this.state.volume,
      "callbacks": {
        "song_change": () => {
          if('mediaSession' in navigator) {
            var song = Amplitude.getSongByIndex(Amplitude.getActiveIndex());
            navigator.mediaSession.metadata = new window.MediaMetadata({
              title: song.name,
              artist: song.artist,
              album: song.album,
              artwork: [
                {src: song.cover_art_url + "&size=96", sizes: '96x96', type: 'image/png'},
                {src: song.cover_art_url + "&size=128", sizes: '128x128', type: 'image/png'},
                {src: song.cover_art_url + "&size=192", sizes: '192x192', type: 'image/png'},
                {src: song.cover_art_url + "&size=256", sizes: '256x256', type: 'image/png'},
                {src: song.cover_art_url + "&size=384", sizes: '384x384', type: 'image/png'},
                {src: song.cover_art_url + "&size=512", sizes: '512x512', type: 'image/png'},
              ]
            });
          }
        },
        "after_play": () => this.acquireWakeLock(),
        "after_pause": () => this.releaseWakeLock(),
        "after_stop": () => this.releaseWakeLock(),
      }
    });

    if('mediaSession' in navigator) {
      navigator.mediaSession.setActionHandler('play', () => {
        Amplitude.play();
      });
      navigator.mediaSession.setActionHandler('pause', () => {
        Amplitude.pause();
      });
      navigator.mediaSession.setActionHandler('nexttrack', () => {
        Amplitude.next();
      });
      navigator.mediaSession.setActionHandler('previoustrack', () => {
        Amplitude.prev();
      });
    }

    if(this.state.isRepeating) {
      Amplitude.setRepeat();
    }
    if(this.state.reorderedSongs.length > 0) {
      Amplitude.playSongAtIndex(this.state.firstActiveSongIndex);
      if(!this.state.playOnRestart) {
        Amplitude.pause();
        // Amplitude does not automatically update amplitude-playing class, so need to do this manually
        var ampPlayingDOMNodes = document.getElementsByClassName("amplitude-playing");
        Array.from(ampPlayingDOMNodes).forEach((node) => {
          node.classList.remove("amplitude-playing");
          node.classList.add("amplitude-paused");
        });
      }
      var audio = Amplitude.audio();
      var durationChangeListener = () => {
        Amplitude.setSongPlayedPercentage(this.state.skipTo);
        audio.removeEventListener("durationchange", durationChangeListener);
      };
      audio.addEventListener("durationchange", durationChangeListener);
    }
  }

  componentDidMount() {
    addQueueNewSongEventSubscriber(this);

    window.addEventListener("visibilitychange", this.visibilityChanged);

    this.updateSize();
    window.addEventListener("resize", this.updateSize);

    if(this.state.shouldRestart) {
      this.restartAmplitude();
    }
  }

  componentDidUpdate() {
    if(this.state.shouldRestart) {
      this.restartAmplitude();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("visibilitychange", this.visibilityChanged);
    window.removeEventListener("resize", this.updateSize);
  }

  visibilityChanged = () => {
    // If user leaves and comes back, reacquire wake lock if Amplitude is playing
    if(!Amplitude.audio().paused && document.visibilityState === 'visible') {
      this.acquireWakeLock();
    }
  }

  acquireWakeLock = () => {
    const requestWakeLock = async () => {
      if(this.wakeLock === null) {
        try {
          this.wakeLock = await navigator.wakeLock.request("screen");
          console.log("Wake lock is active");
          this.wakeLock.addEventListener("release", () => {
            this.wakeLock = null;
            console.log("Wake lock released");
          });
        } catch (err) {
          // The Wake Lock request has failed - usually system related, such as battery.
          console.log(`${err.name}, ${err.message}`);
        }
      }
    };
    requestWakeLock();
  }

  releaseWakeLock = () => {
    if(this.wakeLock !== null) {
      this.wakeLock.release();
    }
  }

  queueNewSongs(songs, firstActiveSongIndex) {
    if(firstActiveSongIndex === undefined) {
      firstActiveSongIndex = (this.state.isShuffling ? randInt(songs.length) : 0);
    }

    var reorderedSongs = songs.slice(0);
    var reorderedIndexes = range(songs.length);

    if(this.state.isShuffling) {
      this.shuffleSongs(reorderedSongs, reorderedIndexes, firstActiveSongIndex);
    }

    this.setState((prevState, props) => ({
      songs: songs.slice(0),
      reorderedSongs: reorderedSongs,
      reorderedIndexes: reorderedIndexes,
      firstActiveSongIndex: (this.state.isShuffling ? 0 : firstActiveSongIndex),
      shouldRestart: true,
      skipTo: 0,
      playOnRestart: true
    }));
  }

  handleVolumeChange(event) {
    this.setState({
      volume: parseInt(event.target.value),
      shouldRestart: false
    });
  }

  toggleRepeat() {
    var newIsRepeating = !this.state.isRepeating;
    config.setIsRepeating(newIsRepeating);
    this.setState({
      isRepeating: newIsRepeating,
      shouldRestart: false
    });
  }

  toggleShuffling() {
    var newIsShuffling = !this.state.isShuffling;
    config.setIsShuffling(newIsShuffling);

    if(this.state.songs.length === 0) {
      this.setState({
        isShuffling: newIsShuffling,
        shouldRestart: false
      });
    }
    else {
      var reorderedSongs = this.state.songs.slice(0);
      var reorderedIndexes = range(this.state.songs.length);
      var currentSongOrigIndex = this.state.reorderedIndexes[Amplitude.getActiveIndex()];
      var isPlaying = !Amplitude.audio().paused;

      if(newIsShuffling) {
        this.shuffleSongs(reorderedSongs, reorderedIndexes, currentSongOrigIndex);
      }

      this.setState({
        reorderedSongs: reorderedSongs,
        reorderedIndexes: reorderedIndexes,
        firstActiveSongIndex: (newIsShuffling ? 0 : currentSongOrigIndex),
        isShuffling: newIsShuffling,
        shouldRestart: true,
        skipTo: Amplitude.getSongPlayedPercentage(),
        playOnRestart: isPlaying
      });
    }
  }

  getNextVisIndex(index) {
    return (index + 1) % this.visualizationTypes.length;
  }

  switchVisualization() {
    var nextVisIndex = this.getNextVisIndex(this.state.visualizationIndex);
    config.setVisIndex(nextVisIndex);
    this.setState({
      visualization: this.visualizationTypes[nextVisIndex],
      visualizationIndex: nextVisIndex,
      shouldRestart: false
    });
  }

  render() {
    var nextVisType = this.visualizationTypes[this.getNextVisIndex(this.state.visualizationIndex)];
    return (
      <div className="player-panel" ref={this.ref} style={{display: (this.state.display ? "initial" : "none")}}>
        <div className="vis-container">
          <QueueView songs={this.state.reorderedSongs} display={this.state.visualization === "playlist"} />
          <AlbumArtView display={this.state.visualization === "album-art"} parent={this} />
        </div>
        <NowPlayingContainer isShuffling={this.state.isShuffling} nextVisType={nextVisType} parent={this} />
        {this.props.children}
      </div>
    );
  }
}

export {
  AlbumNavPanel,
  AllAlbumsNavPanel,
  AllArtistsNavPanel,
  ArtistNavPanel,
  HomeNavPanel,
  LoginPage,
  PlayerPanel,
  PlaylistNavPanel,
  PlaylistsNavPanel
};