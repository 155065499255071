import {storageAvailable} from "./util";

var authChangedEventSubscribers = [];

function publishConfigAuthenticationChangedEvent(newIsAuthenticated) {
  authChangedEventSubscribers.forEach((subscriber) => {
    subscriber.onConfigAuthenticationChanged(newIsAuthenticated);
  });
}

function getAppKey(key) {
  return "subsonic-player-v0.0.1-" + key;
}

function initPrivConfig() {
  var ret = {
    server: null,
    user: null,
    seed: null,
    token: null,
    isAuthenticated: false,
    volume: 50,
    isShuffling: false,
    isRepeating: false,
    visIndex: 0
  };

  if(storageAvailable("localStorage")) {
    var localConfigStr = localStorage.getItem(getAppKey("config"));
    if (localConfigStr !== null) {
      ret = JSON.parse(localConfigStr);
    }
  }

  return ret;
}

var privConfig = initPrivConfig();

function storePrivConfig() {
  if(storageAvailable("localStorage")) {
    var privConfigStr = JSON.stringify(privConfig);
    localStorage.setItem(getAppKey("config"), privConfigStr);
  }
  else {
    console.log("Failed to store config to local storage");
  }
}

class Config {
  getServer() {
    if(privConfig.server === null)
      throw new Error("server not defined");
    else
      return privConfig.server;
  }

  setServer(newServer) {
    privConfig.server = newServer;
    storePrivConfig();
  }

  getUser() {
    if(privConfig.user === null)
      throw new Error("user not defined");
    else
      return privConfig.user;
  }

  setUser(newUser) {
    privConfig.user = newUser;
    storePrivConfig();
  }

  getSeed() {
    if(privConfig.seed === null)
      throw new Error("seed not defined");
    else
      return privConfig.seed;
  }

  setSeed(newSeed) {
    privConfig.seed = newSeed;
    storePrivConfig();
  }

  getToken() {
    if(privConfig.token === null)
      throw new Error("token not defined");
    else
      return privConfig.token;
  }

  setToken(newToken) {
    privConfig.token = newToken;
    storePrivConfig();
  }

  setIsAuthenticated(newIsAuthenticated) {
    privConfig.isAuthenticated = newIsAuthenticated;
    storePrivConfig();
    publishConfigAuthenticationChangedEvent(newIsAuthenticated);
  }

  getIsAuthenticated() {
    return privConfig.isAuthenticated;
  }

  getVolume() {
    return privConfig.volume;
  }

  setVolume(newVolume) {
    privConfig.volume = newVolume;
    storePrivConfig();
  }

  resetConfig() {
    if(storageAvailable("localStorage")) {
      localStorage.removeItem(getAppKey("config"));
    }
    privConfig = initPrivConfig();
  }

  addAuthChangedEventSubscriber(subscriber) {
    authChangedEventSubscribers.push(subscriber);
  }

  removeAuthChangedEventSubscriber(subscriber) {
    authChangedEventSubscribers = authChangedEventSubscribers.filter((item) => (item !== subscriber));
  }

  getIsShuffling() {
    return privConfig.isShuffling;
  }

  setIsShuffling(newIsShuffling) {
    privConfig.isShuffling = newIsShuffling;
    storePrivConfig();
  }

  getIsRepeating() {
    return privConfig.isRepeating;
  }

  setIsRepeating(newIsRepeating) {
    privConfig.isRepeating = newIsRepeating;
    storePrivConfig();
  }

  getVisIndex() {
    return privConfig.visIndex;
  }

  setVisIndex(newVisIndex) {
    privConfig.visIndex = newVisIndex;
    storePrivConfig();
  }
}

var config = new Config();

export {
  config
};